import { useEffect } from "react"

function Logout() {

    useEffect(() => {
        localStorage.clear()
    }, [])

    return (
        <p>Du bist nun ausgeloggt!</p>
    )
}

export default Logout