import { FiAlertTriangle } from 'react-icons/fi'

function FetchFailed() {
    return (
        <div className="fetch-failed">
            <FiAlertTriangle className="icon" />
            <h2>Ein Fehler ist aufgetreten. Mika wird ihn bestimmt sehr bald beheben! 😢</h2>
        </div>
    )
}

export default FetchFailed
