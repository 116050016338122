import { useEffect, useState } from 'react';

import FetchFailed from "../components/FetchFailed"
import { ReactComponent as DeleteImage} from '../assets/delete.svg';

function Upload() {

    const [errorMsg, setErrorMsg] = useState({success: true, msg: '', color: 'red', title: 'Warning'})

    useEffect(() => {
        getMessages()
        setInterval(() => {
            getMessages()
        }, 10000)
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const success = params.success;
        const message = params.error;
        if(message) {
            setErrorMsg({success: success, msg: message, color: success ? 'warning-green' : '', title: 'Success'});
        }
    }, [])

    const [messages, setMessages] = useState([]);
    const [fetchFailed, setFetchFailed] = useState(false)
    function getMessages() {
        fetch(process.env.REACT_APP_API + '/uploadList', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('authJWT')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                window.location.href = '/upload';
            }
            return res
        })
        .then(res => res.json())
        .then(d =>{
            setMessages(d)
        })
        .catch(() => {
            setFetchFailed(true);
        })
    }

    function handleDelete(e, uuid) {
        e.preventDefault();
        fetch(process.env.REACT_APP_API + '/upload/' + uuid, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('authJWT')}`
            }
        })
        .then(res => {
            if (res.status === 400) {
                setErrorMsg({success: false, msg: "Fehler beim löschen der Nachicht.", color: 'red'});
            }
            setErrorMsg({success: false, msg: "Nachicht wurde erfolgreich gelöscht.", color: 'warning-green', title: 'Success'});
            getMessages();
            return res
        })
        .catch(() => {
            setErrorMsg({success: false, msg: "Fehler beim löschen der Nachicht.", color: 'red'});
        })
    }

    return (
        <div>
            {errorMsg.msg !== '' && 
                <div className={`warning ${errorMsg.color}`}>
                    <p>
                        <strong>{errorMsg.title}</strong>
                        <br />
                        {errorMsg.msg}
                    </p>
                </div>
            }
            <div className="upload">
                <h1>Nachricht verschicken</h1>
                <form className="row justify-content-center" action={process.env.REACT_APP_API + "/upload/" + localStorage.getItem('authJWT')} method="POST" encType="multipart/form-data">
                    <div className="col-12">
                        <input type="file" name="file" multiple />
                        <br></br>
                        <label>Titel der Nachricht: </label><br></br>
                        <input type="text" name="title" class="form-control" />
                        <br></br>
                        <label>Beschreibung der Nachricht: </label><br></br>
                        <textarea type="textfield" name="desc" class="form-control" />
                        <br></br>
                        <input type="submit" value="Senden" class="btn btn-primary" />
                    </div>
                </form>
            </div>
            <div className="messages-sent">
                <h1>Nachrichten welche du gesendet hast</h1>
            </div>
            {messages.map((msg, i) => 
            <div className="message-div">
                <a href={'/msg/' + msg.uuid}>
                <div>
                    <div className="title-div">
                        <p className="title">{msg.title}</p>
                        <p className="desc">{msg.description}</p>
                    </div>
                    <div className="footer-div">
                        <img className="avatar" src={msg.author.avatar} alt="Avatar" /> 
                        <p className="footer-text">{msg.author.name}</p>
                        <p className="footer-text">-</p>
                        <p className="footer-text">{new Date(msg.sentAt).toLocaleDateString("de-De", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) } | {((new Date(msg.sentAt).getHours() < 10)?"0":"") + new Date(msg.sentAt).getHours() +":"+ ((new Date(msg.sentAt).getMinutes() < 10)?"0":"") + new Date(msg.sentAt).getMinutes() } Uhr</p>
                    </div>
                    <div className="tools-div">
                        <div className="delete-container">
                            <DeleteImage onClick={(e) => window.confirm('Möchtest du diese Nachicht wirklich löschen?') ? handleDelete(e, msg.uuid) : null} />
                        </div>
                    </div>
                </div>
                </a>
            </div>
            )}
            {fetchFailed &&
                <FetchFailed />
            }
        </div>
    )
}

export default Upload;