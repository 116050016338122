import { useState } from 'react'
import FetchFailed from "../components/FetchFailed"

function Login() {

    const [errorMsg, setErrorMsg] = useState({msg: '', color: 'red'})

    function handleSubmit(e) {
         e.preventDefault()
        setErrorMsg({msg: '', color: 'red'})
        fetch(process.env.REACT_APP_API + '/auth/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: e.target[0].value,
                pwd: e.target[1].value
            })
           
        })
        .then(res => {
            if (res.status === 401) {
                setErrorMsg({msg: 'Ungültiger Username oder Passwort', color: 'red'})
            } else if (res.status === 500) {
                setErrorMsg({msg: 'API Fehler', color: 'red'})
            }
            return res
        })
        .then(res => res.json())
        .then(function (response) {
            if(!response.error) {
                localStorage.setItem('authJWT', response.data.accessToken);
                window.location.href = '/';
            }
        })
        .catch(() => {
            setErrorMsg({msg: 'API Fehler', color: 'red'})
        })
    }

    return (
        <div className="auth">
            {errorMsg.msg !== '' && 
                <div className={`warning ${errorMsg.color}`}>
                    <p>
                        <strong>Warning</strong>
                        <br />
                        {errorMsg.msg}
                    </p>
                </div>
            }
            <div className="form-container">
                <h1>Login</h1>
                <form className="row justify-content-center" onSubmit={handleSubmit}>
                    <div className="col-12">
                        <input type="text" placeholder="Username" />
                    </div>
                    <div className="col-12">
                        <input type="password" placeholder="Password" />    
                    </div>
                    <div className="col-12">
                        <input type="submit" value="Login" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login