function NoMessages() {
    return (
        <div className="fetch-failed">
            <br></br>
            <br></br>
            <br></br>
            <h2>Du hast aktuell keine Nachrichten, du musst wohl warten bis dir jemand eine schickt! 😢</h2>
        </div>
    )
}

export default NoMessages