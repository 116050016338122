import { Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Main from './views/Main.js';

import Login from './views/Login.js';
import Logout from './views/Logout.js';

import ViewMessage from './views/ViewMessage.js';
import Upload from './views/Upload';

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    console.log(location.pathname)
  }, [location]);
}

function App() {

  usePageViews();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(async () => {
    if (window.location.pathname === '/login') return
    if (await isLoggedIn()) {
      setLoggedIn(true)
    } else {
      window.location.href = '/login'
    }
  })

  const isLoggedIn = async () => {
    const token = localStorage.getItem('authJWT')
    if (token) {
        const response = await fetch(`${ process.env.REACT_APP_API }/auth`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${ token }`
                }
            })
            .catch(err => {
                console.log(err)
            })
        if(!response) return true
        if (response.status === 200) {
            return true
        } else {
            return false
        }
    } else return false
  }

  return (
    <div className="app container">
      <div className="content">
        <Routes>
          { loggedIn &&
            <>
          <Route path="/" element={<Main />} />
          <Route path="/msg/:msg" element={<ViewMessage />} />
          <Route path="/upload" element={<Upload />} />
            </>
          }
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;