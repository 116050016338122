import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'

import FetchFailed from "../components/FetchFailed"
import NoMessages from "../components/NoMessages"

function Main() {
    const navigate = useNavigate();

    let timer;

    window.onload = timerReset;
    document.onkeypress = timerReset;
    document.onmousemove = timerReset;
    document.onmousedown = timerReset;
    document.ontouchstart = timerReset;
    document.onclick = timerReset;
    document.onscroll = timerReset;

    function timerElapsed() {
        window.location.reload();
    };
    
    function timerReset() {
        clearTimeout(timer);
        timer = setTimeout(timerElapsed, 10 * 60 * 1000); // 10 mins
    }

    const [messages, setMessages] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [fetchFailed, setFetchFailed] = useState(false);

    useEffect(() => {
        const storageMessages = localStorage.getItem('messages');
        if(storageMessages !== null) {
            setMessages(JSON.parse(storageMessages));
            if(JSON.parse(storageMessages).length > 0) {
                setLoading(false);
            }
        }
        getMessages()
        setInterval(() => {
            getMessages()
        }, 10000)
    }, [])

    function getMessages() {
        fetch(process.env.REACT_APP_API + '/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('authJWT')}`
            }
        })
        .then(res => {
            if (res.status === 401) {
                window.location.href = '/upload';
            }
            return res
        })
        .then(res => res.json())
        .then(d =>{
            setMessages(d);
            localStorage.setItem('messages', JSON.stringify(d));
            setLoading(false);
            setFetchFailed(false);
        })
        .catch(() => {
            setFetchFailed(true);
            setLoading(false);
        })
    }

    return (
        <div>
            {!isLoading && messages.length !== 0 && messages.map((msg, i) => 
            <div className={"message-div " + (msg.viewed ? "viewed" : "not-viewed")}>
                <div className="title-div">
                    <div className="open-div" onTouchStart={() => navigate('/msg/' + msg.uuid)} onClick={() => navigate('/msg/' + msg.uuid)}>
                        <p className="open-button">Öffnen</p>
                    </div>
                    <p className="title">{msg.title}</p>
                    <p className="desc">{msg.description}</p>
                </div>
                <div className="footer-div">
                    <img className="avatar" src={msg.author.avatar} alt="Avatar" /> 
                    <p className="footer-text">{msg.author.name}</p>
                    <p className="footer-text">-</p>
                    <p className="footer-text">{new Date(msg.sentAt).toLocaleDateString("de-De", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) } | {((new Date(msg.sentAt).getHours() < 10)?"0":"") + new Date(msg.sentAt).getHours() +":"+ ((new Date(msg.sentAt).getMinutes() < 10)?"0":"") + new Date(msg.sentAt).getMinutes() } Uhr</p>
                </div>
            </div>
            )}
            {messages.length === 0 && !fetchFailed && !isLoading && 
                <NoMessages />
            }
            {fetchFailed &&
                <FetchFailed />
            }
            <div className="fetch-failed">
                <br></br>
                <br></br>
                <br></br>
                <h2>{isLoading === true ? 'Lade Nachichten...' : 'Ende'}</h2>
                <br></br>
            </div>
        </div>
    )
}

export default Main;